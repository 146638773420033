import React from 'react';
import Style from '/src/components/calendarLink.module.css';

export default (props) => {
	const { events } = props;

	if (events.length === 0) {
		return null;
	}

	const sampleDate = events[0].date;
	const year = sampleDate.getFullYear();
	const month = sampleDate.getMonth() + 1;
	const caption = `${year}年${month}月`;

	const weeks = [];
	const date = new Date(year, month - 1, 1);
	while (date.getMonth() + 1 === month) {
		const week = [];
		for (const dow of [0, 1, 2, 3, 4, 5, 6]) {
			if (date.getDay() === dow && date.getMonth() + 1 === month) {
				const dayEvents = events.filter((event) => event.date.getDate() === date.getDate());
				week.push({
					day: date.getDate(),
					dayId: year + '-' + month + '-' + date.getDate(),
					events: dayEvents,
				});
				date.setDate(date.getDate() + 1);
			} else {
				week.push(null);
			}
		}
		weeks.push(week);
	}

	return (
		<div>
			<table border={1} className={Style.table}>
				<caption>{caption}</caption>
				<thead>
					<tr>
						<th>日</th>
						<th>月</th>
						<th>火</th>
						<th>水</th>
						<th>木</th>
						<th>金</th>
						<th>土</th>
					</tr>
				</thead>
				<tbody>
					{weeks.map((week, index) => {
						return (
							<tr key={index}>
								{week.map((dayObj, index) => {
									if (dayObj === null) {
										return <td key={index}></td>;
									}
									const day = dayObj.day;
									const dayId = dayObj.dayId;
									const dayEvents = dayObj.events;

									return (
										<td key={index} id={'calendar' + dayId}>
											<div className={Style.day}>{day}</div>
											{dayEvents.map((event) => {
												return (
													<a key={event.id} href={'#' + event.id}>
														{event.strTime}
													</a>
												);
											})}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<script
				dangerouslySetInnerHTML={{
					__html: `
const today = new Date();
const todayId = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
const styleElement = document.createElement("style");
document.head.appendChild(styleElement);
const sheet = styleElement.sheet;
sheet.insertRule("#calendar" + todayId + " { border: dashed red; }", 0);
`,
				}}
			></script>
		</div>
	);
};
